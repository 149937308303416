import ApiHelper from '@/helpers/ApiHelper'
import Crud from '@/store/classes/crud'
import _ from 'lodash'
import { JOB_SERVER } from '@/servers'

const crud = new Crud({
    module: 'task'
})

export default {
    namespaced: true,
    state: {
        ...crud.state,
        // filter: {
        //     active: null,
        //     name: null
        // },
    },
    mutations: {
        ...crud.mutations
    },
    actions: {
        ...crud.actions,
        async upload(context, data) {
            return await ApiHelper.post(`${JOB_SERVER}/api/task/upload`, data);
        },
        async themesUpload(context, data) {
            return await ApiHelper.post(`${JOB_SERVER}/api/task/themes-upload`, data);
        },
        async fetchThemes() {
            return await ApiHelper.get(`${JOB_SERVER}/api/task/themes`);
        },
        async fetchSubtaskHints(context, data) {
            return await ApiHelper.get(`${JOB_SERVER}/api/hint-of-subtask`, data);
        },
    },
    getters: {
        grades: () => {
            return _.map(_.keys(Array(11)), (i) => {
                i = _.parseInt(i);
                return {
                    value: i + 1,
                    text: (i + 1) + ' кл.'
                };   
            })
        }
    }
}